import _ from "underscore";
import val from "core/val";
import { I18N } from "aurelia-i18n";
import { Viewbag } from "helpers/view-bag";
import { autoinject } from "aurelia-framework";
import { ErrorManager } from "error-management/error-manager";

import dateHelper from "helpers/dateHelper";
import EmailHelper from "helpers/emailHelper";
import labelHelper from "helpers/labelHelper";
import routerHelper from "helpers/routerHelper";
import settingHelper from "helpers/settingHelper";
import { NotificationHelper } from "helpers/notification-helper";

import clientService from "services/clientService";
import contactService from "services/contactService";
import materialService from "services/materialService";
import workOrderService from "services/workOrderService";
import workorderTimesheetService from "services/workorderTimesheetService";

@autoinject
export class FieldServiceSummary {

    public val: any = val;

    public client: any;
    public contactNameAvailable: boolean = true;
    public contacts: any[] = [];
    public workOrder: any;
    public emailAvailable: boolean = false;

    public labor: any;
    public material: any[] = [];
    public signatureAvailable: boolean = false;
    public isFromSignature: boolean = false;
    public email: string | null = "";
    public isCompleted: boolean = false;
    public clientLanguage: string | null = "";
    public specifiedLanguage: string | null = "";
    public txtSignature: string = "";
    public signature: any;

    public labelHelper: typeof labelHelper = labelHelper;
    public dateHelper: typeof dateHelper = dateHelper;

    constructor(public readonly i18n: I18N, private readonly emailHelper: EmailHelper, private readonly notificationHelper: NotificationHelper, public viewbag: Viewbag, private readonly errorManager: ErrorManager) {
    }

    public async activate(params: any): Promise<any> {
        this.parseQueryString(params.q);
        await this.loadData(decodeURIComponent(params.workOrderId));
    }

    public async loadData(workOrderId: string): Promise<any> {
        this.isFromSignature = this.viewbag && this.viewbag.value && this.viewbag.value.isFromFieldServiceSignature === true;

        const client = await clientService.getClientInfoForWorkOrder(workOrderId);
        this.initClient(client);

        const workOrderPromise = await workOrderService.get(workOrderId);
        const laborsPromise = await workorderTimesheetService.listForSummary(workOrderId, this.specifiedLanguage ? this.specifiedLanguage : undefined);
        const materialPromise = await materialService.listForWorkOrder(workOrderId);

        this.labor = await laborsPromise;
        this.workOrder = await workOrderPromise;
        this.initMaterials(await materialPromise);
        this.initSignature();
   }

    public get loadContacts(): any {
        return {
            transport: (params: any, success: any): any => {
                return contactService.getByCustomerCode(this.client.Id, params.data.filter, params.data.page || 1)
                    .then(success);
            },
            mapResults: (item: any): any => {
                return { id: item.No, text: item.Name + ", " + item.FirstName, data: item.Email };
            }
        };
    }

    public initClient(client: any): any {
        if (client === null) {
            this.client = { MobilitySignatureRequiredness: 0 };
            this.clientLanguage = "";
        } else {
            this.client = client;
            this.clientLanguage = labelHelper.getLanguageAcronym(client.Language);
        }

        this.specifiedLanguage = !this.isCompleted ? this.clientLanguage : null;
    }

    public initMaterials(material: any): void {
        this.material = [
            {
                title: this.i18n.tr("Material", this.specifiedLanguage),
                items: _.filter(material, (mat: any) => mat.ItemType === 0 || mat.ItemType === 2)
            },
            { title: this.i18n.tr("Salary", { lng: this.specifiedLanguage }), items: _.where(material, { ItemType: 1 }) },
            { title: this.i18n.tr("Subcontractor", { lng: this.specifiedLanguage }), items: _.where(material, { ItemType: 5 }) },
            { title: this.i18n.tr("Equipment", { lng: this.specifiedLanguage }), items: _.where(material, { ItemType: 3 }) },
            { title: this.i18n.tr("Misc", { lng: this.specifiedLanguage }), items: _.where(material, { ItemType: 4 }) }
        ];

        this.material = _.filter(this.material, (mat: any) => mat.items.length > 0);
    }

    public async isEmailValid(): Promise<boolean> {
        if (this.email && this.email.length > 0) {
            const invalidEmails: any[] = await this.emailHelper.findInvalidEmailsInString(this.email);
            if (invalidEmails.length === 0) {
                return true;
            }
            this.notificationHelper.showError(this.i18n.tr("err_InvalidEmail") + " " + invalidEmails.join(", "), "");
        }
        return false;
    }

    public initSignature(): any {
        if (this.isFromSignature) {
            this.signatureAvailable = !this.workOrder.IsReadOnly && this.client.MobilitySignatureRequiredness !== 2;
        } else {
            this.signatureAvailable = false;
        }

        if (this.signatureAvailable && (this.client && this.client.MobilityEmailAvailability !== undefined)) {
            if (this.client.MobilityEmailAvailability === 0) {
                this.emailAvailable = this.client.GeneralConfigSaveFormWorkorder;
            } else {
                this.emailAvailable = this.client.MobilityEmailAvailability === 1 ? true : false;
            }
        } else {
            this.emailAvailable = false;
        }

    }

    public async onSignatureCompleted(event: CustomEvent): Promise<void> {

        const signatureData = event.detail;

        if (await this.isEmailValid()) {
            if (!this.txtSignature || this.txtSignature.length === 0 || signatureData.length === 0) {
                this.notificationHelper.showError(this.i18n.tr("err_SignatureRequired"), "", { timeOut: 0 });
            } else {
                const signature: any = {
                    Comment: this.txtSignature.replace(" ", " "),
                    Email: this.emailAvailable ? this.email : "",
                    FileData: signatureData
                };
                await workOrderService.sign(this.workOrder.Id, settingHelper.getSelectedDispatchModel(), signature);
                await workOrderService.printForm(this.workOrder.Id, this.email);

                routerHelper.navigateBack();
            }
        }
    }

    public parseQueryString(querystring: string): void {
        const json = routerHelper.getQuerystring(querystring);

        if (!json) { return; }

        this.isCompleted = !(json.isCompleted === "false") || false;
    }
}
